import { PROFILE_RCS_STATUS } from './enums/ProfileRcsStatus'

export default class ProfileRcs {
  id
  statusId
  messageTypeId
  sender
  message
  agentName
  description
  banner
  logo
  color
  numbers
  numbersLabel
  webPages
  webPagesLabel
  emails
  emailLabel
  privacyTerm
  serviceTerm
  companyName
  companyWeb
  companyPersonContact
  companyPersonRol
  companyPersonPhone
  companyPersonEmail
  companyLinkOpt
  companyTextUnsubscribed
  companyAgentOperators
  companyAgentOperator1
  companyAgentOperator2
  companyAgentOperator3

  constructor () {
    this.id = null
    this.sender = ''
    this.message = ''
    this.messageTypeId = []
    this.agentName = ''
    this.description = ''
    this.banner = ''
    this.logo = ''
    this.color = '#029ee1'
    this.numbers = []
    this.numbersLabel = ''
    this.webPages = []
    this.webPagesLabel = ''
    this.emails = []
    this.emailLabel = ''
    this.privacyTerm = ''
    this.serviceTerm = ''
    this.companyName = ''
    this.companyWeb = ''
    this.companyPersonContact = ''
    this.companyPersonRol = ''
    this.companyPersonPhone = ''
    this.companyPersonEmail = ''
    this.companyLinkOpt = ''
    this.companyLinkAttachment = ''
    this.companyTextUnsubscribed = ''
    this.companyAgentOperators = 'Monocanal (operador) / Multicanal (operador1, operador2, etc.)'
    this.companyAgentOperator1 = 'Operador / 34612345678'
    this.companyAgentOperator2 = 'Operador / 34612345678'
    this.companyAgentOperator3 = 'Operador / 34612345678'
    this.statusId = PROFILE_RCS_STATUS.DRAFT
  }

  load (profileRcs) {
    this.id = profileRcs.id
    this.statusId = profileRcs.user_profile_rcs_status_id
    this.messageTypeId = JSON.parse(profileRcs.user_profile_rcs_message_type_id)
    this.sender = profileRcs.sender
    this.message = profileRcs.message
    this.agentName = profileRcs.agent_name
    this.description = profileRcs.description
    this.banner = profileRcs.banner
    this.logo = profileRcs.logo
    this.color = profileRcs.color
    this.numbers = JSON.parse(profileRcs.numbers)
    this.numbersLabel = profileRcs.number_label
    this.webPages = JSON.parse(profileRcs.web_page)
    this.webPagesLabel = profileRcs.web_page_label
    this.emails = JSON.parse(profileRcs.email)
    this.emailLabel = profileRcs.email_label
    this.privacyTerm = profileRcs.privacy_term
    this.serviceTerm = profileRcs.service_term
    this.companyName = profileRcs.company_name
    this.companyWeb = profileRcs.company_web
    this.companyPersonContact = profileRcs.company_person_contact
    this.companyPersonRol = profileRcs.company_person_rol
    this.companyPersonPhone = profileRcs.company_person_phone
    this.companyPersonEmail = profileRcs.company_person_email
    this.companyLinkOpt = profileRcs.company_link_opt
    this.companyLinkAttachment = profileRcs.company_link_attachment
    this.companyTextUnsubscribed = profileRcs.company_text_unsubscribed
    this.companyAgentOperators = profileRcs.company_agent_operator
    this.companyAgentOperator1 = profileRcs.company_agent_operator1
    this.companyAgentOperator2 = profileRcs.company_agent_operator2
    this.companyAgentOperator3 = profileRcs.company_agent_operator3
  }

  formatData () {
    return {
      id: this.id,
      user_profile_rcs_status_id: PROFILE_RCS_STATUS.DRAFT,
      user_profile_rcs_message_type_id: this.messageTypeId,
      sender: this.sender,
      message: this.message,
      agent_name: this.agentName,
      description: this.description,
      banner: this.banner,
      logo: this.logo,
      color: this.color,
      numbers: this.numbers,
      number_label: this.numbersLabel,
      web_page: this.webPages,
      web_page_label: this.webPagesLabel,
      email: this.emails,
      email_label: this.emailLabel,
      privacy_term: this.privacyTerm,
      service_term: this.serviceTerm,
      company_name: this.companyName,
      company_web: this.companyWeb,
      company_person_contact: this.companyPersonContact,
      company_person_rol: this.companyPersonRol,
      company_person_phone: this.companyPersonPhone,
      company_person_email: this.companyPersonEmail,
      company_link_opt: this.companyLinkOpt,
      company_link_attachment: this.companyLinkAttachment,
      company_text_unsubscribed: this.companyTextUnsubscribed,
      company_agent_operator: this.companyAgentOperators,
      company_agent_operator1: this.companyAgentOperator1,
      company_agent_operator2: this.companyAgentOperator2,
      company_agent_operator3: this.companyAgentOperator3,
    }
  }
}
