import getEnv from '@/util/env'
import EventBus from '@/util/EventBus'
import { Color } from '@/util/Color'

import ProfileRcsService from '../../services/profile-rcs/profile-rcs.service'
import ProfileRcs from '../../models/profile-rcs/ProfileRcs'
import ProfileRcsPreview from './ProfileRcsPreview/ProfileRcsPreview.vue'
import { PROFILE_RCS_STATUS } from '../../models/profile-rcs/enums/ProfileRcsStatus'

export default {
  name: 'ProfileRcs',
  props: {},
  components: {
    ProfileRcsPreview,
  },
  data: function () {
    return {
      profileRcs: new ProfileRcs(),
      apiUrl: getEnv('VUE_APP_API_URL'),
      imageSelection: 'banner',
      Color: Color,
      profileRcsMessagesTypes: [
        {
          id: 1,
          name: this.$t('Promocional'),
        },
        {
          id: 2,
          name: this.$t('Transaccional'),
        },
      ],
      loading: false,
      tabs: 0,
      profileVerifyDialogConfirm: false,
    }
  },
  created () {
    const profileId = this.$route.query.id

    if (profileId) {
      this.getProfile(profileId)
    }
  },
  mounted () {
    EventBus.$on('multimediaSelected', this.updateImage)
  },
  unmounted () {
    EventBus.$off('multimediaSelected', this.updateImage)
  },
  computed: {
    canSave () {
      return [PROFILE_RCS_STATUS.DRAFT, PROFILE_RCS_STATUS.REJECT].includes(this.profileRcs.statusId)
    },
    canValidate () {
      return this.profileRcs.banner !== '' &&
             this.profileRcs.logo !== '' &&
             this.profileRcs.companyLinkAttachment !== '' &&
        [PROFILE_RCS_STATUS.DRAFT, PROFILE_RCS_STATUS.REJECT].includes(this.profileRcs.statusId)
    },
  },
  watch: {},
  methods: {
    selectBanner () {
      this.imageSelection = 'banner'
      EventBus.$emit('showMultimedia', 'imagenes', false)
    },
    selectLogo () {
      this.imageSelection = 'logo'
      EventBus.$emit('showMultimedia', 'imagenes', false)
    },
    selectLinkAttachment () {
      this.imageSelection = 'linkAttachment'
      EventBus.$emit('showMultimedia', 'imagenes', false)
    },
    updateImage (url, type) {
      if (this.imageSelection === 'banner') {
        this.profileRcs.banner = this.apiUrl + url
      }

      if (this.imageSelection === 'logo') {
        this.profileRcs.logo = this.apiUrl + url
      }

      if (this.imageSelection === 'linkAttachment') {
        this.profileRcs.companyLinkAttachment = this.apiUrl + url
      }
    },
    removeBanner () {
      this.profileRcs.banner = ''
    },
    removeLogo () {
      this.profileRcs.logo = ''
    },
    removeLinkAttachment () {
      this.profileRcs.companyLinkAttachment = ''
    },
    async sendProfileToVerify () {
      await this.save()
      ProfileRcsService.sendVerify({
        id: this.profileRcs.id,
      })
      .then((response) => {
        this.profileVerifyDialogConfirm = false
        this.$router.push('/users/profile-rcs')
      })
    },
    async save () {
      if (this.profileRcs.id === null) {
        await this.createProfile()
      } else {
        await this.updateProfile()
      }
    },
    getProfile (profileId) {
      ProfileRcsService.find(profileId)
        .then((response) => {
          this.profileRcs.load(response.data)
        })
    },
    async createProfile () {
      this.loading = true
      const data = this.profileRcs.formatData()
      await ProfileRcsService.create(data)
      .then(response => {
        this.profileRcs.id = response.data.id
        EventBus.$emit('showAlert', 'success', this.$t('Perfil creado con éxito'))
      })
      .catch(() => {
        EventBus.$emit('showAlert', 'danger', this.$t('Ocurrió un error al crear el perfil'))
      })
      .finally(() => {
        this.loading = false
      })
    },
    async updateProfile () {
      const data = this.profileRcs.formatData()
      await ProfileRcsService.update(data)
      .then((response) => {
        EventBus.$emit('showAlert', 'success', this.$t('Perfil actualizado con éxito'))
      })
      .catch(() => {
        EventBus.$emit('showAlert', 'danger', this.$t('Ocurrió un error al actualizar el perfil'))
      })
    },
    removeNumber (value) {
      this.profileRcs.numbers = this.profileRcs.numbers.filter((number) => {
        return number !== value
      })
    },
    removeWebPage (value) {
      this.profileRcs.webPages = this.profileRcs.webPages.filter((webPage) => {
        return webPage !== value
      })
    },
    removeEmail (value) {
      this.profileRcs.emails = this.profileRcs.emails.filter((email) => {
        return email !== value
      })
    },
  },
}
