export default {
  name: 'ProfileRcsPreview',
  props: {
    profileRcs: {
      require: true,
      type: Object,
      tab: 'info',
    },
  },
  components: {},
  data: function () {
    return {}
  },
  created () {},
  mounted () {},
  unmounted () {},
  computed: {},
  watch: {},
  methods: {},
}
